@import 'themes/default';

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/placeholders';

@import 'base/reset';
@import 'base/typography';

@import 'components/dashboard';
@import 'components/icons';
@import 'components/search-input';
@import 'components/scrollbar';

@import 'layout/layout';
@import 'layout/spacing';
@import 'layout/widgets';

/**
 * Ändert die Anzeige für verlinkungen wie zb. Kontaktmanagment Kontaktliste - Erreichbarkeit, Telefonnummer, Website
 */

a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
}

a:hover {
    color: var(--color-primary);
}

/**
 * Überschreibt den mat-tab-body-wrapper sodass der Tabinhalt immer den restlichen Platz einnehmen kann.
 * Ist dazu da, um die Scrolleiste an der richtigen Stelle anzuzeigen.
 */
mat-tab-group > .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
}

/**
 * Hintergrundfarbe aller Tables gleichrichten
 */
mat-table,
mat-header-row,
mat-header-cell {
    background-color: $color-bg-lighter !important;
}

mat-header-row {
    font-weight: bold !important;
}

mat-row:hover {
    background: var(--color-table-hover);

    .pencil {
        visibility: visible;
        background-color: var(--color-table-hover);
    }
}

tr:hover {
    .pencil {
        visibility: visible;
        background-color: var(--color-table-hover);
    }
}

.pencil {
    // Icons neben dem Eintrag anordnen
    position: relative;
    visibility: hidden;
    float: right;
    margin-right: 1rem;
}

/**
 * Disabled von Buttons mit eigener Farbe da sonst der Material-Style nicht zieht
 */
.mat-mdc-icon-button.mat-mdc-button-disabled {
    opacity: 25%;
}

/**
 * Mandantenwechsel in der Navigationsleiste
 */
k5-nav-content .mat-mdc-select-arrow-wrapper {
    display: none !important;
}

/**
 * Link in primary color
 */
.primary-link {
    color: var(--color-primary);
}

.primary-link:hover {
    cursor: pointer;
    color: var(--color-primary-900);
}

/**
 * Überschreibt den Snackbar Style
 * Weißer Hintergrund mit schwarzer Textfarbe
 */
.mat-mdc-snack-bar-container {
    &.k5-snackbar {
        --mdc-snackbar-container-color: var(--color-nav-background);
        --mdc-snackbar-supporting-text-color: var(--color-nav-foreground);
    }
}

/**
 * Setzt die Outlines von Buttons auf die Primärfarbe
 */
.mat-mdc-outlined-button:not(.mat-mdc-button-disabled) {
    --mdc-outlined-button-outline-color: var(--color-primary) !important;
}

/**
 * Überschreibt den Material-Tab-Style
 *   - größere Schrift
 *   - Linie darunter zur besseren Abtrennung
 */
.mat-mdc-tab .mdc-tab__text-label {
    font-size: 1rem;
}

.mat-mdc-tab-labels {
    border-bottom: 1px solid lightgray;
}

/**
 * Überschreibt die Dropdown-Options, sodass diese die gesamte Breite des Overlays nutzen
 */
.mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
}

/**
 * Überschreibt den Style aller Eingabefelder, sodass diese nicht mehr hinterlegt sind
 */
.mdc-text-field--filled {
    --mdc-filled-text-field-container-color: transparent;
    --mdc-filled-text-field-disabled-container-color: transparent;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mdc-text-field {
    padding: 0px !important;
    --mat-form-field-container-height: 48px;
}

input.mat-mdc-chip-input {
    margin: 0px !important;
}

.mat-mdc-floating-label {
    top: 2.2rem !important;
}

.mdc-floating-label--float-above {
    transform: translateY(-145%) scale(0.75) !important;
}

/**
 * Das ursprüngliche Padding war 16px oben und unten.
 * Padding wurde oben erhöht um den Vorschlagswert genau an der Stelle der Texteingabe zu platzieren.
 */
.mat-mdc-form-field-infix {
    padding-top: 24px !important;
    padding-bottom: 8px !important;
}

/**
 * Alignment des Select-Arrows mit dem Text
 */
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: none !important;
}

/**
 * Alignment der Error-Meldungen
 */
.mat-mdc-form-field-error-wrapper {
    padding: 0px !important;
}

/**
 * Alignment der Hint-Meldungen
 */
.mat-mdc-form-field-hint-wrapper {
    padding: 0px !important;
}

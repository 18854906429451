.search-input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: white;
    border-radius: 1.25rem;
    border: 1px solid lightgray;
}

.search-input-container:focus-within {
    border: 1px solid var(--color-primary);
}

.search-input {
    font-size: 1rem;
    padding: 0.375rem;
    border: none;
    min-width: 0;
    flex-grow: 1;
}

.search-input:focus {
    outline: none;
}

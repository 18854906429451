/**
 * Klassen für Spacing
 * 1rem = 16px
 */

$no-space: 0rem;
$space-xs: 0.25rem;
$space-sm: 0.5rem;
$space-md: 1rem;
$space-lg: 1.5rem;
$space-xl: 3rem;

/**
 * Padding
 */
.p-none {
    padding: $no-space;
}

.p-xs {
    padding: $space-xs;
}

.p-sm {
    padding: $space-sm;
}

.p-md {
    padding: $space-md;
}

.p-lg {
    padding: $space-lg;
}

.p-xl {
    padding: $space-xl;
}

/**
 * Padding left
 */
.pl-none,
.px-none {
    padding-left: $no-space;
}

.pl-xs,
.px-xs {
    padding-left: $space-xs;
}

.pl-sm,
.px-sm {
    padding-left: $space-sm;
}

.pl-md,
.px-md {
    padding-left: $space-md;
}

.pl-lg,
.px-lg {
    padding-left: $space-lg;
}

.pl-xl,
.px-xl {
    padding-left: $space-xl;
}

/**
 * Padding right
 */
.pr-none,
.px-none {
    padding-right: $no-space;
}

.pr-xs,
.px-xs {
    padding-right: $space-xs;
}

.pr-sm,
.px-sm {
    padding-right: $space-sm;
}

.pr-md,
.px-md {
    padding-right: $space-md;
}

.pr-lg,
.px-lg {
    padding-right: $space-lg;
}

.pr-xl,
.px-xl {
    padding-right: $space-xl;
}

/**
 * Padding top
 */
.pt-none,
.py-none {
    padding-top: $no-space;
}

.pt-xs,
.py-xs {
    padding-top: $space-xs;
}

.pt-sm,
.py-sm {
    padding-top: $space-sm;
}

.pt-md,
.py-md {
    padding-top: $space-md;
}

.pt-lg,
.py-lg {
    padding-top: $space-lg;
}

.pt-xl,
.py-xl {
    padding-top: $space-xl;
}

/**
 * Padding bottom
 */
.pb-none,
.py-none {
    padding-bottom: $no-space;
}

.pb-xs,
.py-xs {
    padding-bottom: $space-xs;
}

.pb-sm,
.py-sm {
    padding-bottom: $space-sm;
}

.pb-md,
.py-md {
    padding-bottom: $space-md;
}

.pb-lg,
.py-lg {
    padding-bottom: $space-lg;
}

.pb-xl,
.py-xl {
    padding-bottom: $space-xl;
}

/**
 * Margin
 */
.m-none {
    margin: $no-space;
}

.m-xs {
    margin: $space-xs;
}

.m-sm {
    margin: $space-sm;
}

.m-md {
    margin: $space-md;
}

.m-lg {
    margin: $space-lg;
}

.m-xl {
    margin: $space-xl;
}

/**
 * Margin left
 */
.ml-none,
.mx-none {
    margin-left: $no-space;
}

.ml-xs,
.mx-xs {
    margin-left: $space-xs;
}

.ml-sm,
.mx-sm {
    margin-left: $space-sm;
}

.ml-md,
.mx-md {
    margin-left: $space-md;
}

.ml-lg,
.mx-lg {
    margin-left: $space-lg;
}

.ml-xl,
.mx-xl {
    margin-left: $space-xl;
}

/**
 * Margin right
 */
.mr-none,
.mx-none {
    margin-right: $no-space;
}

.mr-xs,
.mx-xs {
    margin-right: $space-xs;
}

.mr-sm,
.mx-sm {
    margin-right: $space-sm;
}

.mr-md,
.mx-md {
    margin-right: $space-md;
}

.mr-lg,
.mx-lg {
    margin-right: $space-lg;
}

.mr-xl,
.mx-xl {
    margin-right: $space-xl;
}

/**
 * Margin top
 */
.mt-none,
.my-none {
    margin-top: $no-space;
}

.mt-xs,
.my-xs {
    margin-top: $space-xs;
}

.mt-sm,
.my-sm {
    margin-top: $space-sm;
}

.mt-md,
.my-md {
    margin-top: $space-md;
}

.mt-lg,
.my-lg {
    margin-top: $space-lg;
}

.mt-xl,
.my-xl {
    margin-top: $space-xl;
}

/**
 * Margin bottom
 */
.mb-none,
.my-none {
    margin-bottom: $no-space;
}

.mb-xs,
.my-xs {
    margin-bottom: $space-xs;
}

.mb-sm,
.my-sm {
    margin-bottom: $space-sm;
}

.mb-md,
.my-md {
    margin-bottom: $space-md;
}

.mb-lg,
.my-lg {
    margin-bottom: $space-lg;
}

.mb-xl,
.my-xl {
    margin-bottom: $space-xl;
}

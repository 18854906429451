.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.sticky {
  position: sticky
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.top-1\/4 {
  top: 25%
}

.z-\[2000\] {
  z-index: 2000
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.mb-6 {
  margin-bottom: 1.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-6 {
  margin-top: 1.5rem
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3
}

.block {
  display: block
}

.\!flex {
  display: flex !important
}

.flex {
  display: flex
}

.table {
  display: table
}

.table-row {
  display: table-row
}

.grid {
  display: grid
}

.\!hidden {
  display: none !important
}

.hidden {
  display: none
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-3\/5 {
  height: 60%
}

.h-8 {
  height: 2rem
}

.h-\[1px\] {
  height: 1px
}

.h-\[3px\] {
  height: 3px
}

.h-full {
  height: 100%
}

.h-px {
  height: 1px
}

.max-h-80 {
  max-height: 20rem
}

.max-h-\[50\%\] {
  max-height: 50%
}

.max-h-\[6\.5rem\] {
  max-height: 6.5rem
}

.max-h-\[65vh\] {
  max-height: 65vh
}

.max-h-full {
  max-height: 100%
}

.min-h-16 {
  min-height: 4rem
}

.min-h-8 {
  min-height: 2rem
}

.min-h-\[4rem\] {
  min-height: 4rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-16 {
  width: 4rem
}

.w-8 {
  width: 2rem
}

.w-80 {
  width: 20rem
}

.w-96 {
  width: 24rem
}

.w-\[700px\] {
  width: 700px
}

.w-auto {
  width: auto
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.min-w-8 {
  min-width: 2rem
}

.min-w-full {
  min-width: 100%
}

.max-w-80 {
  max-width: 20rem
}

.max-w-\[8rem\] {
  max-width: 8rem
}

.max-w-full {
  max-width: 100%
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-grow {
  flex-grow: 1
}

.grow {
  flex-grow: 1
}

.basis-6\/12 {
  flex-basis: 50%
}

.basis-full {
  flex-basis: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.place-content-center {
  place-content: center
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-evenly {
  justify-content: space-evenly
}

.gap-1 {
  gap: 0.25rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem
}

.gap-x-3 {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem
}

.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.gap-y-4 {
  row-gap: 1rem
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.self-center {
  align-self: center
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-ellipsis {
  text-overflow: ellipsis
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre-line {
  white-space: pre-line
}

.break-words {
  overflow-wrap: break-word
}

.break-all {
  word-break: break-all
}

.rounded {
  border-radius: 0.25rem
}

.rounded-\[\.25rem\] {
  border-radius: .25rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.border {
  border-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-l-4 {
  border-left-width: 4px
}

.border-solid {
  border-style: solid
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity, 1))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1))
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity, 1))
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1))
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity, 1))
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1))
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity, 1))
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity, 1))
}

.border-l-transparent {
  border-left-color: transparent
}

.bg-\[\#dde2e7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 226 231 / var(--tw-bg-opacity, 1))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1))
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1))
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1))
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1))
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1))
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1))
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1))
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1))
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1))
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.\!pl-8 {
  padding-left: 2rem !important
}

.\!pr-0 {
  padding-right: 0px !important
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pl-4 {
  padding-left: 1rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pr-4 {
  padding-right: 1rem
}

.pr-8 {
  padding-right: 2rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.\!text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-5xl {
  font-size: 3rem;
  line-height: 1
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.\!font-bold {
  font-weight: 700 !important
}

.font-bold {
  font-weight: 700
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.tracking-normal {
  letter-spacing: 0em
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1))
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1))
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1))
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1))
}

.opacity-50 {
  opacity: 0.5
}

.shadow-\[0px_-4px_6px_-1px_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
  --tw-shadow: 0px -4px 6px -1px rgba(0,0,0,0.1);
  --tw-shadow-colored: 0px -4px 6px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1))
}

.hover\:underline:hover {
  text-decoration-line: underline
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

@media (min-width: 768px) {
  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }
}

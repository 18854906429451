@import "abstracts/variables";

gridster {
    // Überschreiben der Hintergrundfarbe von Gridster
    background: $color-bg-lighter !important;
}

/* Ändert die Darstellung einer Kachel */
gridster-item {
    border-radius: 1rem;
    border: 1px solid $color-lightest-gray;
    overflow: auto;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

/* Setzt den Style für die Gridster Vorschau */
gridster-preview {
    border-radius: 1rem;
    background: var(--color-primary) !important;
    opacity: 0.2 !important;
}

@use '@angular/material' as mat;
@import '../../../node_modules/@k5next/ui-components/assets/theme.scss';

@include mat.core();

@font-face {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../assets/fonts/Source_Sans_3/SourceSans3-Regular.woff2') format('woff2');
}

/**
 * Default theme colors and fonts
 */

* {
    font-family: 'Source Sans 3', sans-serif;
}

/**
 * Default Theme
 */
$mat-custom-petrol: (
    50: #e3edf0,
    100: #bad3d9,
    200: #8cb5c0,
    300: #5d97a6,
    400: #3b8193,
    500: #186b80,
    600: #156378,
    700: #11586d,
    800: #0e4e63,
    900: #083c50,
    A100: #3af9ff,
    A200: #00f4fc,
    A400: #00dbe2,
    A700: #00cdd3,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: rgba(black, 0.87),
        A100: white,
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: rgba(black, 0.87)
    )
);

$k5next-typography: mat.define-typography-config(
    $font-family: 'Source Sans 3',
    $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $headline-4: mat.define-typography-level(34px, 40px, 400),
    $headline-5: mat.define-typography-level(24px, 32px, 400),
    $headline-6: mat.define-typography-level(20px, 32px, 400),
    $subtitle-1: mat.define-typography-level(16px, 28px, 400),
    $subtitle-2: mat.define-typography-level(16px, 28px, 400),
    $body-1: mat.define-typography-level(16px, 20px, 400),
    $body-2: mat.define-typography-level(14px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500)
);

// build default theme
$blue-primary: mat.define-palette(mat.$blue-palette);
$blue-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

$blue-theme: mat.define-light-theme(
    (
        color: (
            primary: $blue-primary,
            accent: $blue-accent
        ),
        typography: $k5next-typography
    )
);

@include mat.all-component-themes($blue-theme);
@include k5next-components-theme($blue-theme);
@include mat.typography-hierarchy($k5next-typography);

// Standardschriftgröße von Tooltips auf 14px festlegen
$tooltip-typography-level: mat.define-typography-level(
    $font-family: 'Source Sans 3',
    $font-size: 14px
);
$tooltip-typography: mat.define-typography-config(
    $caption: $tooltip-typography-level
);
@include mat.tooltip-typography($tooltip-typography);

// Standardschriftgröße von Checkboxen auf 16px festlegen
$checkbox-typography-level: mat.define-typography-level(
    $font-family: 'Source Sans 3',
    $font-size: 16px
);
$checkbox-typography: mat.define-typography-config(
    $body-2: $checkbox-typography-level
);
@include mat.checkbox-typography($checkbox-typography);

/**
 * Petrol theme
 */
.mat-custom-petrol {
    $petrol-primary: mat.define-palette($mat-custom-petrol);
    $petrol-accent: mat.define-palette($mat-custom-petrol);
    $k5-theme: mat.define-light-theme(
        (
            color: (
                primary: $petrol-primary,
                accent: $petrol-accent
            )
        )
    );
    @include k5next-components-theme($k5-theme);
    @include mat.all-component-colors($k5-theme);
}

/**
 * Orange theme
 */
.mat-custom-orange {
    $orange-primary: mat.define-palette(mat.$deep-orange-palette);
    $orange-accent: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);
    $orange-theme: mat.define-light-theme(
        (
            color: (
                primary: $orange-primary,
                accent: $orange-accent
            )
        )
    );
    @include k5next-components-theme($orange-theme);
    @include mat.all-component-colors($orange-theme);
}

/**
 * Green theme
 */
.mat-custom-green {
    $green-primary: mat.define-palette(mat.$green-palette);
    $green-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);
    $green-theme: mat.define-light-theme(
        (
            color: (
                primary: $green-primary,
                accent: $green-accent
            )
        )
    );
    @include k5next-components-theme($green-theme);
    @include mat.all-component-colors($green-theme);
}

/**
 * Cyan theme
 */
.mat-custom-cyan {
    $cyan-primary: mat.define-palette(mat.$cyan-palette);
    $cyan-accent: mat.define-palette(mat.$cyan-palette, A400, A100, A200);
    $cyan-theme: mat.define-light-theme(
        (
            color: (
                primary: $cyan-primary,
                accent: $cyan-accent
            )
        )
    );
    @include k5next-components-theme($cyan-theme);
    @include mat.all-component-colors($cyan-theme);
}

@use 'sass:map';
@use '@angular/material' as mat;
@tailwind utilities;

// Liest Farben aus dem verwendeten Material-Theme aus und verwendet diese - muss in dem verwendeten styles.scss-File importiert werden
@mixin k5next-components-theme($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $primary-color: mat.get-color-from-palette($primary-palette, 500);
    $primary-hover-color: mat.get-color-from-palette($primary-palette, 50);

    // @layer: Diese ermöglichen uns die Aufteilung unserer Styles in streng getrennte Schichten, um Konflikte zu vermeiden.
    // @layer utilities wird gebraucht um :hover im HTML für diese Klassen zu unterstützen
    // siehe mehr unter: https://www.oidaisdes.org/de/cascade-layers-in-angular.de/
    @layer utilities {
        .border-l-primary {
            border-left-color: $primary-color;
        }

        .text-primary {
            color: $primary-color;
        }

        .bg-primary-hover:hover {
            background-color: $primary-hover-color;
        }

        .bg-primary {
            background-color: $primary-color;
        }
    }
}

/**
 * Color variables
 */

// Neues Petrol mit höheren Kontrast zum Hintergrund laut Marketing OG in Sprint 31
$color-primary-new: #186B80;
// Altes Petrol mit geringeren Kontrast zum Hintergrund laut Marketing OG, welches bei Logos verwendet wird
$color-primary-old: #659eac;

// Hover
$color-primary-hover: #eeeeee;

// Active
$color-primary-active: #cecece;

// Background
$color-bg-lightest: #fff;
$color-bg-lighter: #fafafa;
$color-bg-light: #dde2e7;
$color-bg-dark: #71767a;
$color-bg-darker: #474849;
$color-bg-darkest: #373838;

// Success, Errors, Warning, Information
$color-success: #28a745;
$color-info: #17a2b8;
$color-warning: #ffc107;
$color-danger: #dc3545;

// Diverses
$color-white: #fff;
$color-lightest-gray: #e0e0e0;
$color-light-gray: #bcbbc2;
$color-gray: #696d70;

/**
 * Breakpoints
 */
$breakpoint-xs: 0;
$breakpoint-sm: 36rem;
$breakpoint-md: 48rem;
$breakpoint-lg: 62rem;
$breakpoint-xl: 75rem;

/**
 * Font sizes
 */
$font-size-xs: 0.5rem;
$font-size-sm: 0.75rem;
$font-size-md: 1rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 3rem;
$font-size-3xl: 16rem;

/**
 * Überschreiben der Standardwerte und setzen der Standards
 */

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

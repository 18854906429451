/**
 * Typographie Styles
 */

mark {
    font-weight: bold;
    background: none;
}

/**
 * Text
 */
.bold {
    font-weight: bold;
}

.fs-xs {
    font-size: $font-size-xs;
}

.fs-sm {
    font-size: $font-size-sm;
}

.fs-md {
    font-size: $font-size-md;
}

.fs-lg {
    font-size: $font-size-lg;
}

.fs-xl {
    font-size: $font-size-xl;
}
.fs-xxl {
    font-size: $font-size-xxl;
}
.fs-3xl {
    font-size: $font-size-3xl;
}

/**
 * Foregrounds
 */
.fg-primary {
    color: var(--color-primary);
}

// Definition für die Hauptfarbe bei der Übergabe des Farbwertes
// Aktuell: an die Grid-Komponente
.primary-color {
    color: var(--color-primary) !important;
}

.fg-gray {
    color: $color-gray;
}

.fg-white {
    color: $color-white;
}

// Error, Warning, Success, Info
.fg-danger {
    color: $color-danger;
}

.fg-warning {
    color: $color-warning;
}

.fg-info {
    color: $color-info;
}

.fg-success {
    color: $color-success;
}

/**
 * Backgrounds
 */
.bg-primary {
    background-color: $color-bg-lighter;
}

.bg-primary-main {
    background-color: var(--color-primary);
}

.bg-primary-hover-radio:hover {
    background-color: var(--color-primary-100);
}

.bg-primary-selected {
    background-color: var(--color-primary-50);
}

.bg-light {
    background-color: $color-bg-light;
}

.bg-gray {
    background-color: $color-gray;
}

.bg-lighter {
    background-color: $color-bg-lighter;
}

.bg-lightest {
    background-color: $color-bg-lightest;
}

.bg-white {
    background-color: $color-white;
}

.no-text-wrap {
    white-space: nowrap;
}

/**
 * Text align
 */
.text-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

/**
 * Horizontal line
 */
.line {
    height: 1px;
    background: $color-light-gray;
}

/**
 * FontAwesome sizing
 */
.fa-md {
    font-size: 1.1rem;
    line-height: 2rem;
}

// Blur-Effekt
.blur-effect {
    filter: blur(4px);
}

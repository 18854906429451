/**
 * Datei für scss 'mixins' (= Pseudo-Funktionen)
 *
 * ACHTUNG: Mixins werden bei jeder Verwendung in das CSS kopiert und vergrößert somit das
 *          finale CSS. Wenn keine Parameter notwendig sind, ist es besser 'Placeholder' zu verwenden!
 *          siehe auch: https://www.sitepoint.com/sass-mixin-placeholder/
 */

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

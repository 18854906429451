/**
 * Custom Icons
 */
.fa-k5-puzzle {
    height: 2rem;
    background-color: var(--color-primary);
    -webkit-mask: url("../../assets/icons/puzzle.svg") no-repeat center;
    mask: url("../../assets/icons/puzzle.svg") no-repeat center;
}

.fa-k5-industry-xmark {
    -webkit-mask: url("../../assets/icons/industry-mark.svg") no-repeat center;
    mask: url("../../assets/icons/industry-mark.svg") no-repeat center;
}

.fa-k5-industry-xmark-lg {
    width: 2rem;
    height: 0.9rem;
    -webkit-mask: url("../../assets/icons/industry-mark.svg") no-repeat center;
    mask: url("../../assets/icons/industry-mark.svg") no-repeat center;
}

.fa-k5-puzzle-alt {
    height: 2rem;
    background-color: var(--color-primary);
    -webkit-mask: url("../../assets/icons/puzzle-alt.svg") no-repeat center;
    mask: url("../../assets/icons/puzzle-alt.svg") no-repeat center;
}

.fa-k5-wahl {
    height: 2rem;
    background-color: var(--color-primary);
    -webkit-mask: url("../../assets/icons/wahl.svg") no-repeat center;
    mask: url("../../assets/icons/wahl.svg") no-repeat center;
}

.fa-k5-wahl-alt {
    height: 2rem;
    background-color: var(--color-primary);
    -webkit-mask: url("../../assets/icons/wahl-alt.svg") no-repeat center;
    mask: url("../../assets/icons/wahl-alt.svg") no-repeat center;
}

.fa-k5-austria-flag {
    height: 1rem;
    width: 1rem;
    background-image: url("../../assets/icons/austria-flag.svg");
    background-repeat: no-repeat;
}

.fa-k5-eu-flag {
    height: 1rem;
    width: 1rem;
    background-image: url("../../assets/icons/eu-flag.svg");
    background-repeat: no-repeat;
}

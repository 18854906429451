/**
 * Utility Klassen für Layouts
 */

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.full-space {
    width: 100%;
    height: 100%;
}

/**
 * Flexbox utilities
 */
.k5-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-only {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

.vertical-flex {
    display: flex;
    flex-flow: column;
    height: 100%;
}

/**
 * Separators
 */
.horizontal-separator {
    height: 1px;
    background: $color-light-gray;
}

.vertical-separator {
    width: 1px;
    background: $color-gray;
}

/**
 * Style für Optionsmenü bei Telefonnummer
 */
.tel-menu-trigger {
    position: fixed;
    visibility: hidden;
}
